import React from "react";
import {Btn} from "../common/Btn";

export const Header = () => {
    return <header>
        <h1>
            <strong>Mega </strong> PortoFolio
        </h1>
        {/*<button>ToDo CRUD</button>*/}
    </header>
}