export const sampleBase = [
    {
        title: 'Myśl poranna',
        description: 'Idzie Chopin i Bach',
    },
    {
        title: 'Myśl popołudniowa',
        description: 'Jeść i pracować',
    },
    {
        title: 'Myśl dzienna',
        description: 'Koniec pracy to poczatek pracy',
    },
    {
        title: 'Myśl wieczorna',
        description: 'Spać się powinno ale myśl o pracy łechce',
    },
    {
        title: 'Myśl nocna',
        description: 'Możesz próbować spać, praca praca praca',
    },
];